/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { firstValueFrom, Observable } from 'rxjs';

export function convertStringToType<T>(s: string | null): T | null {
	if (s == null) return null;

	if (s.trim() == '') return null;

	try {
		return JSON.parse(s) as T;
	} catch {
		return null;
	}
}

// eslint-disable-next-line @typescript-eslint/naming-convention
// const OMIT_PROPERTY = ['links', 'label', 'value'];

export interface CompareResult<T = any> {
	value: T;
	equal: boolean;
}

export function isObject<T>(object: T): boolean {
	return object !== null && typeof object === 'object';
}

export function isObjEmpty<T>(object: T): boolean {
	if (object === null || object === undefined) return true;

	return Object.keys(object).length === 0;
}

type D = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

export function equals<T>(o1: T, o2: T): boolean {
	if (o1 === o2) return true;
	if (o1 === null || o2 === null) return false;
	if (o1 !== o1 && o2 !== o2) return true; // NaN === NaN

	const t1 = typeof o1;
	const t2 = typeof o2;

	let length: number, keySet: any;

	if (t1 == t2 && t1 == 'object') {
		if ((o1 as D)['label']) delete (o1 as D)['label'];
		if ((o1 as D)['links']) delete (o1 as D)['links'];

		if ((o2 as D)['label']) delete (o2 as D)['label'];
		if ((o2 as D)['links']) delete (o2 as D)['links'];

		if (Array.isArray(o1)) {
			if (!Array.isArray(o2)) return false;

			if ((length = o1.length) == o2.length) {
				for (let k = 0; k < length; k++) {
					if (!equals(o1[k], o2[k])) return false;
				}
				return true;
			}
		} else {
			if (Array.isArray(o2)) {
				return false;
			}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			keySet = Object.create(null);

			for (const key in o1) {
				if (!equals(o1[key] ?? null, o2?.[key] ?? null)) {
					return false;
				}
				keySet[key] = true;
			}

			for (const key in o2) {
				if (!(key in keySet) && typeof o2[key] !== 'undefined') {
					return false;
				}
			}

			return true;
		}
	}
	return false;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function compareObject<T extends Object>(objectCurrent: T, objectTemp: T): CompareResult<T> {
	const isEqual = compare(objectCurrent, objectTemp);
	return {
		value: objectCurrent,
		equal: isEqual,
	};
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function generateUUID(): string {
	let d = new Date().getTime();
	const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});

	return uuid;
}

export function completedCharacter(value: string, count: number, character = '0'): string {
	if (value == null) return '';

	const length = value.trim().length;
	let completed = '';

	for (let i = length; i < count; i++) {
		completed += character;
	}

	return completed + value;
}

function convertToType<T>(formControl: AbstractControl | null): Required<T> | null {
	if (formControl == null) return null;

	const formValue = formControl.value as unknown;

	if (formValue == null) return null;

	if (typeof formValue === 'object') {
		const object = formValue as NgSelectOption<T> & { links: [] };

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { label, value, links, ...res } = object;
		return { ...res } as unknown as Required<T>;
	}

	return formValue as Required<T>;
}

function clearObject<T>(object: T): T | null {
	if (object && typeof object === 'object') {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const e = object as NgSelectOption<any> & { links: [] };
		// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-assignment
		const { label, value, links, ...res } = e;

		if (isObjEmpty(res)) return null;
		return res as T;
	}
	return object;
}

export function convertFormGroupToModel<T>(formGroup: UntypedFormGroup, strict = false): Required<T> {
	if (!strict) return { ...formGroup.getRawValue() } as Required<T>;

	const object = formGroup.getRawValue() as Required<T>;
	// eslint-disable-next-line prefer-const
	let obj: { [key: string]: any } = {};

	for (const key in object) {
		if (Object.prototype.hasOwnProperty.call(object, key)) {
			const element = object[key];
			if (element && typeof element === 'object') {
				if (Array.isArray(element)) {
					obj[key] = (element as unknown as []).map(clearObject);
				} else {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const e = element as NgSelectOption<any> & { links: [] };
					// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-assignment
					const { label, value, links, ...res } = e;

					if (isObjEmpty(res)) obj[key] = null;
					else obj[key] = res as unknown;
				}
			} else {
				obj[key] = element;
			}
		}
	}
	return obj as Required<T>;
}

export function convertFormToType<T>(form: UntypedFormControl | AbstractControl | null): Required<T> | null;
export function convertFormToType<T, K>(form: UntypedFormGroup, property: keyof K): Required<T> | null;
export function convertFormToType<T, K>(form: UntypedFormControl | AbstractControl | UntypedFormGroup | null, property?: keyof K): Required<T> | null {
	if (form == null) return null;

	if (form instanceof UntypedFormControl) return convertToType<T>(form);

	if (form instanceof UntypedFormGroup) {
		return convertToType<T>(form.get(property as string));
	}

	return null;
}

export function convertObserverToPromise<T>(...observer: Observable<T>[]): Promise<T>[] {
	return observer.map((o) => firstValueFrom(o));
}

export function convertTypeRequired<T>(value: T): Required<T> {
	return value as Required<T>;
}

export function deletePropertyNullOrUndefined<T>(obj: T): T {
	const cloneObj = structuredClone(obj);

	for (const key in cloneObj) {
		if (Object.prototype.hasOwnProperty.call(cloneObj, key)) {
			const element = cloneObj[key];
			if (element == null) delete cloneObj[key];
			else if (typeof cloneObj[key] === 'object') {
				deletePropertyNullOrUndefined(cloneObj[key]);
				if (Array.isArray(cloneObj[key]) && (cloneObj[key] as any).length === 0) {
					delete cloneObj[key];
				}
				if ((element as any)['label']) delete (element as any)['label'];
				if ((element as any)['links']) delete (element as any)['links'];
			}
		}
	}

	return cloneObj;
}

export function clearProperty<T>(obj: T): T {
	const object = structuredClone(obj);

	for (const key in object) {
		if (Object.prototype.hasOwnProperty.call(object, key)) {
			const element = object[key];

			if (Array.isArray(element) && element.length == 0) {
				delete object[key];
			}
			if (Array.isArray(element) && element.length > 0) {
				for (const obj of element) {
					clearProperty(obj);
					// for (const keyObject in obj) {
					// 	this.clear(obj, keyObject);
					// }
				}
			}

			clearPropertyObject(object, key);
		}
	}

	return object;
}

function clearPropertyObject<T>(object: T, key: keyof T): void {
	if (!Array.isArray(object[key]) && object[key] != null && typeof object[key] == 'object') {
		for (const k in object[key]) {
			if (!k.startsWith('id') || (k.startsWith('id') && typeof object[key][k] != 'object' && !String(key).includes(k.substring(2)))) {
				delete object[key][k];
			}
			if (k.startsWith('id') && typeof object[key][k] == 'object') {
				for (const kNested in object[key]) {
					clearPropertyObject(object[key], kNested);
				}
			}
		}
	}
}

export function compare<T>(objectApi: T, currentObject: T): boolean {
	const isNullOrUndefined = (val: any) => val === null || val === undefined;
	if (objectApi === currentObject) return true;
	if (isNullOrUndefined(objectApi) || isNullOrUndefined(currentObject)) return false;
	if (objectApi !== objectApi && currentObject !== currentObject) return true; // NaN === NaN

	for (const k in objectApi) {
		const elementT = objectApi[k];
		const elementC = currentObject[k];
		if (isNullOrUndefined(elementT) && !isNullOrUndefined(elementC)) {
			return false;
		} else if (!isNullOrUndefined(elementT) && isNullOrUndefined(elementC)) {
			return false;
		}
		if (Array.isArray(elementT) && Array.isArray(elementC)) {
			if (elementT.length != elementC.length) {
				return false;
			}
			for (let i = 0; i < elementT.length; i++) {
				if (!compare(elementT[i], elementC[i])) {
					return false;
				}
			}
		}
		if (!Array.isArray(objectApi[k]) && typeof objectApi[k] == 'object') {
			for (const kObject in objectApi[k]) {
				if (typeof objectApi[k][kObject] != 'object' && objectApi[k][kObject] != currentObject[k][kObject]) {
					return false;
				} else if (typeof objectApi[k][kObject] == 'object') {
					if (!compare(objectApi[k], currentObject[k])) {
						return false;
					}
				}
			}
		}
		if (!Array.isArray(objectApi[k]) && typeof objectApi[k] != 'object' && currentObject[k] != null && objectApi[k] != currentObject[k]) {
			return false;
		}
		if (!Array.isArray(objectApi[k]) && typeof objectApi[k] != 'object' && currentObject[k] == null && objectApi[k] != currentObject[k]) {
			return false;
		}
	}
	// for (const k of Object.getOwnPropertyNames(currentObject)) {
	// 	if (!k.startsWith('total') && k.endsWith('s') && currentObject[k].length > 0) {
	// 		if (objectApi[k] == null && typeof objectApi[k] != 'function') {
	// 			return false;
	// 		}
	// 	}
	// }
	return true;
}
